import * as React from 'react';
import * as SplashScreen from 'expo-splash-screen';
import { 
	Text, 
	View, 
	Pressable,
	Platform,
Linking,
	StatusBar } from 'react-native';
import { 
	NavigationContainer,
	InitialState,
	useNavigationContainerRef } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Icons from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Dimensions } from 'react-native';
import { useFonts } from 'expo-font';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';

// Get custom
import { SCREENS } from './screens/all';
import { globals } from './lib/globals';
import { style, styles } from './lib/styles';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import UserProvider from './shared/services/user.service';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCrIG0h66FJdWF5bSWvZdLAuAI8-pCTba4",
  authDomain: "dwdwork-mvpiq.firebaseapp.com",
  projectId: "dwdwork-mvpiq",
  storageBucket: "dwdwork-mvpiq.appspot.com",
  messagingSenderId: "403342059206",
  appId: "1:403342059206:web:3379b279fea797cf7659c7",
  measurementId: "G-G2KGQ9SL57"
};

const Tab = createBottomTabNavigator();
const NAVIGATION_PERSISTENCE_KEY = 'NAVIGATION_STATE';
const THEME_PERSISTENCE_KEY = 'THEME_TYPE';
const DIRECTION_PERSISTENCE_KEY = 'DIRECTION';
const SCREEN_NAMES = Object.keys(SCREENS) as (keyof typeof SCREENS)[];

const App = () => {
	const [isReady, setIsReady] = React.useState(Platform.OS === 'web');
	const [initialState, setInitialState] = React.useState<InitialState | undefined>();
	const navigationRef = useNavigationContainerRef();
	const [fontsLoaded, fontError] = useFonts({
		'DIN': require('./assets/fonts/DINAlternateBold.ttf'),
		'Futura': require('./assets/fonts/FuturaLT-Bold.ttf'),
	});

	// Initialize Firebase
	const app = initializeApp(firebaseConfig);

	React.useEffect(() => {
	
		const restoreState = async () => {
		try {
			const initialUrl = await Linking.getInitialURL();

			if (Platform.OS !== 'web' || initialUrl === null) {
			const savedState = await AsyncStorage?.getItem(
				NAVIGATION_PERSISTENCE_KEY
			);

			const state = savedState ? JSON.parse(savedState) : undefined;

			if (state !== undefined) {
				setInitialState(state);
			}
			}
		} finally {
			try {
			const themeName = await AsyncStorage?.getItem(THEME_PERSISTENCE_KEY);

			} catch (e) {
				console.log('Issue with setup: ', e);
			}

			setIsReady(true);
		}
		};

		restoreState();
	}, []);

	if (!isReady) {
		return null;
	}

	return (
		<UserProvider>
				<View style={styles.app}>

					{globals.isiOS == true && (
						<StatusBar barStyle={'light-content'}/>
					)}

					<NavigationContainer
						ref={navigationRef}
						initialState={initialState}
						onReady={() => {
							SplashScreen.hideAsync();
						}}
						onStateChange={(state) => AsyncStorage.setItem(
							NAVIGATION_PERSISTENCE_KEY,
							JSON.stringify(state)
						)}
						fallback={<Text>Loading…</Text>}
						documentTitle={{
							formatter: (options, route) => `${options?.title ?? route?.name} - Git Gud`,
						}}
					>

						<Tab.Navigator
							sceneContainerStyle={[style.bg.black]}
							screenOptions={({ route, navigation }) => ({
								headerTitleAlign: 'center',
								
								// headerRight: () => (
								// 	<Pressable
								// 		onPress={() => navigation.navigate('Profile')}
								// 		style={[style.margin.mr1]}>
								//		<Icons.MaterialCommunityIcons name={'account-circle'} size={24} color={globals.lightPurple} type={'material-community'} />
								// 	</Pressable>
								// ),
								hheaderShown: false,
								tabBarStyle: {
									backgroundColor: globals.darkGray,
									position: 'absolute',
									borderTopColor: globals.lightPink },
									tabBarIcon: ({ focused, color, size }) => {
										let name;
										let type;
										let iconStyle;

										if (route.name === 'Dashboard') {
											return <Icons.FontAwesome name={'home'} color={color} size={size} />
										} else if (route.name === 'BettingTracker') {
											name = focused ? 'dice-multiple' : 'dice-multiple';
										} else if (route.name === 'Simulator') {
											name = focused ? 'gamepad-variant' : 'gamepad-variant'; 
										} else if (route.name === 'Profile') {
											name = 'account';
										} else if (route.name === 'News') {
											name = focused ? 'newspaper-variant-multiple' : 'newspaper-variant-multiple';
										} else if (route.name === 'InformationScreen') {
											return <Icons.FontAwesome name={'info'} color={color} size={size} />
										} else if (route.name === 'Mantine') {
											name='github';
										} else if (route.name === 'Awards') {
											name = 'trophy';
										}

										return <Icons.MaterialCommunityIcons name={name} size={size} color={color} style={iconStyle ? iconStyle : null}/>;
										
									},
								tabBarActiveTintColor: globals.pink,
								tabBarInactiveTintColor: globals.lightPurple,
								tabBarShowLabel: false,
							})}>

							{SCREEN_NAMES.map((name, index) => (
								<Tab.Screen
									key={index.toString()}
									name={name}
									component={SCREENS[name].component}
									options={{
										// headerStyle: {
										// 	borderBottomColor: globals.lightPink,
										// 	backgroundColor: globals.darkGray
										// },
										// headerTitleStyle: {
										// 	textAlign: 'center',
										// 	color: globals.white,
										// 	fontWeight: 'bold'
										// },
										headerShown: false,
									}}
								/>
							))}
						</Tab.Navigator>

					</NavigationContainer>

				</View>
		</UserProvider>
	);
};

export default App;