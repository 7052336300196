// Get dependencies
import * as React from 'react';
import { 
    View,
    Text,
    StyleSheet,
    Pressable,
    Image,
    Keyboard, 
    Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

// Get custom
import { style } from '../../lib/styles';
import { useUser } from '../../shared/services/user.service';
import { Heading, Paragraph } from '../../shared/ContentItems';

export const User = () => {
    const [user, setUser] = React.useState(undefined);
    const [displayName, setDisplayName] = React.useState(undefined)

    const userData = useUser();

    React.useEffect(() => {        
        if (userData) {
            setUser(userData.user ?? userData);
            
            if (user) {
                setDisplayName(user.displayName ?? user.email);
            }
        }
    })

    return (
        <View style={[style.flex.std, style.flex.column]}>
            <Heading>Welcome, {displayName}</Heading>
            <Paragraph>Here you will see a list of your current stats.</Paragraph>
        </View>
    );
};

const componentStyles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
