import { Platform } from "react-native";
import { initializeApp } from "firebase/app";
import { createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword, signOut, updateProfile, initializeAuth } from "firebase/auth";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import AsyncStorage from '@react-native-async-storage/async-storage';

const firebaseConfig = {
    apiKey: "AIzaSyCrIG0h66FJdWF5bSWvZdLAuAI8-pCTba4",
    authDomain: "dwdwork-mvpiq.firebaseapp.com",
    projectId: "dwdwork-mvpiq",
    storageBucket: "dwdwork-mvpiq.appspot.com",
    messagingSenderId: "403342059206",
    appId: "1:403342059206:web:3379b279fea797cf7659c7",
    measurementId: "G-G2KGQ9SL57"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const firebasedb = {
    register: register,
    login: login,
    logout: logout
}

async function register({firstName, lastName, email, password}) {

    const resp = await createUserWithEmailAndPassword(auth, email, password);

    await updateProfile(resp.user, {displayName: `${firstName} ${lastName}`});

    return resp;
}

async function login({email, password}) {

    const resp = await signInWithEmailAndPassword(auth, email, password);
    const user = resp.user;
    const token = user.getIdToken().then(idToken => console.log(idToken));

    await AsyncStorage.setItem('loggedIn', 'true');

    return resp;
}

async function logout() {

    await signOut(auth);
    await AsyncStorage.setItem('loggedIn', 'false');
    window.location.reload();
}

if (Platform.OS === 'web') {
    const appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider('6LcTtRYqAAAAAFgXNnKba-zt1n1qodovvOB-ZoWI'),
        isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
    });
}